import React from "react"
import {Link} from "gatsby"
import "../styles/styles.scss"

const NotFoundPage = () => (
  <div className="error-page">
    <section className="error-section">
      <h1 className="error-page-title">404</h1>
      <p>
      This page does not exist, but this <Link className="error-link" to="/">one</Link> does.
      </p>
    </section>
  </div>
)

export default NotFoundPage
